import React from 'react';
import { string, number, bool } from 'prop-types';

import { Button } from '@one-thd/sui-atomic-components';
import { Curbside } from '@one-thd/sui-icons';

export const CurbsideButton = ({
  orderNumber = undefined,
  isReadyForPickup = false,
  storeNumber = null
}) => {

  return (
    <div className="sui-pt-4">
      <Button
        id="curbside-button"
        fullWidth
        href={`/myaccount/order-details?orderNumber=${orderNumber}`}
        startIcon={Curbside}
        variant={isReadyForPickup ? 'primary' : 'secondary'}
      >
        Get It Curbside
      </Button>
    </div>
  );
};

CurbsideButton.propTypes = {
  isReadyForPickup: bool,
  orderNumber: string,
  storeNumber: number
};