import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Typography, Card, CardMedia, CardContent,
  Skeleton, SkeletonContent, SkeletonLine, SkeletonBlock,
  Button
} from '@one-thd/sui-atomic-components';
import * as Icons from '@one-thd/sui-icons';
import { string } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  arrayOf,
  customType,
  namedFragment,
  params,
  shape,
  string as stringType,
  typename,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { useImpression } from '@thd-olt-component-react/impression';
import { selectPreferredImageSrc } from '../utils/selectPreferredImage';

const ShopByCategory = ({ componentId, componentClass = '' }) => {
  const { data, loading, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    },
  });

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('shop-by-category.ready');
  }, []);

  const title = 'Shop By Category';
  const { channel } = useContext(ExperienceContext);
  const [showMore, setShowMore] = useState(false);
  const categoryList = data?.component?.shopByCategoryNavigationItems;
  const isMobile = channel === 'mobile';
  const categoryRef = useRef(null);

  if (error || (!categoryList?.length && !loading)) {
    return null;
  }

  const OnViewLessClick = () => {
    setShowMore(false);
    if (categoryRef?.current) {
      categoryRef.current.scrollIntoView({
        behavior: 'auto'
      });
    }
  };
  /* eslint-disable react/prop-types */
  const CustomImage = ({ imgSrc, imgAlt }) => {
    return (
      <img
        className="sui-aspect-square"
        src={imgSrc}
        alt={imgAlt}
        height="24"
        width="24"
      />
    );
  };

  const ShoppingCategoryCard = (category, index) => {
    const { ref, clickID } = useImpression({
      data: {
        id: category?.id || '',
        name: 'ShoppingCategories',
        component: 'ShoppingCategories',
        position: index + 1,
        type: 'content',
        category: category?.title
      }
    });
    const showStencilIcon = category?.stencilIcon !== undefined
    && category?.stencilIcon !== null
    && category?.stencilIcon !== '';
    const StencilIcon = showStencilIcon ? Icons[category?.stencilIcon] : <></>;
    const imgSrc = selectPreferredImageSrc({ damImage: category?.previewImage, defaultImageUrl: category?.icon?.url });
    return (
      <div
        ref={ref}
        // eslint-disable-next-line react/no-unknown-property
        clickid={clickID}
        key={index}
        data-testid={`category ${index}`}
      >
        <a href={category?.link} className="sui-grid">
          <Card
            disablePadding
            disableGutters
            value={category?.id}
            aria-label={category?.id}
            orientation="horizontal"
          >
            <CardContent orientation="horizontal" disableGutters grow>
              <div className="sui-flex sui-items-center">
                <div className="sui-flex sui-grow-0 sui-shrink-0 sui-basis-auto sui-mr-2">
                  {showStencilIcon ? <StencilIcon /> : (
                    <CardMedia
                      component={(() => <CustomImage imgSrc={imgSrc} imgAlt={`${category?.title} Placeholder`} />
                      )}
                    />
                  )}
                </div>
                <div className="sui-flex-auto sui-text-xs sui-leading-normal sui-font-bold">
                  {category?.title}
                </div>
              </div>
            </CardContent>
          </Card>
        </a>
      </div>
    );
  };

  const LoadingPlaceholders = () => {
    const placeHolderCount = isMobile ? 8 : 18;
    const placeholderElements = [];
    for (let i = 0; i < placeHolderCount; i += 1) {
      placeholderElements.push(
        <Skeleton
          orientation="horizontal"
          disablePadding
          disableGutters
          key={i}
        >
          <SkeletonContent disableGutters disableShrink orientation="horizontal" grow>
            <div className="sui-flex sui-items-center">
              <div className="sui-flex sui-grow-0 sui-shrink-0 sui-basis-auto sui-mr-2">
                <SkeletonBlock aspect="square" height={6} width={6} />
              </div>
              <div className="sui-flex-auto sui-text-xs sui-leading-normal sui-font-bold">
                <SkeletonLine variant="single" fullWidth />
              </div>
            </div>
          </SkeletonContent>
        </Skeleton>
      );
    }
    if (isMobile) {
      placeholderElements.push(
        <div className="sui-flex sui-items-center sui-justify-center" key={9}>
          <SkeletonBlock aspect="wide" height={11} width={24} />
        </div>);
    }
    return placeholderElements;
  };

  const ShoppingCategories = () => {
    const numberofCategory = (showMore || !isMobile) ? categoryList?.length : 8;
    let reducedCategoryList = categoryList?.slice(0, numberofCategory)?.map((category, index) => {
      return ShoppingCategoryCard(category, index);
    });
    if (isMobile) {
      reducedCategoryList.push(
        <div className="sui-flex sui-items-center sui-justify-center" key={9}>
          {showMore
            ? (
              <Button
                variant="text"
                endIcon={Icons.ArrowUp}
                onClick={OnViewLessClick}
              >
                Show Less
              </Button>
            )
            : (
              <Button
                variant="text"
                endIcon={Icons.ArrowDown}
                onClick={() => { setShowMore(true); }}
              >
                Show More
              </Button>
            )}
        </div>
      );
    }
    return reducedCategoryList;
  };

  return (
    <div className="sui-grid sui-p-2" ref={categoryRef} data-component="ShopByCategory">
      <Typography variant="h2">
        {title}
      </Typography>
      {loading && (
        <div className="sui-grid sui-grid-cols-1 sm:sui-grid-cols-3 lg:sui-grid-cols-5 sui-gap-4 sui-mt-5">
          <LoadingPlaceholders />
        </div>
      ) }
      {data && (
        <div
          className="sui-grid sui-grid-cols-1 sm:sui-grid-cols-3 lg:sui-grid-cols-5 sui-gap-4 sui-mt-5"
        >
          <ShoppingCategories />
        </div>
      )}
    </div>
  );
};

ShopByCategory.propTypes = {
  componentId: string.isRequired,
  componentClass: string,
};

ShopByCategory.displayName = 'ShopByCategory';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: stringType()
      })
    )
  }),
  damDownloadedContent: shape({
    url: stringType()
  }),
  __typename: typename('DamMedia')
});

ShopByCategory.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass')
      .enum(['ShopByCategory'])
      .isRequired(),
  }).shape({
    ShopByCategory: namedFragment({ inline: true, fragmentType: 'ShopByCategory' }).shape({
      id: stringType(),
      componentName: stringType(),
      shopByCategoryNavigationItems: arrayOf(
        shape({
          id: stringType(),
          title: stringType(),
          link: stringType(),
          icon: shape({
            url: stringType(),
          }),
          stencilIcon: stringType(),
          previewImage: DamMediaFragment,
        }),
      ),
      __typename: typename('ShopByCategory'),
    }),
  }),
};

export { ShopByCategory };
