import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { number, string, func } from 'prop-types';
import { QueryProvider, QueryContext } from '@thd-nucleus/data-sources';
import { RecentlyViewed as RecentlyViewedComponent } from '@thd-olt-component-react/thd-recs-containers';
import { Card, CardTitle, Typography } from '@one-thd/sui-atomic-components';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { HomepageContext } from '../context/HomepageContext';

/**
 * We used to wrap this in a Hydrator that had a very specific id for
 * analytics purposes (rv-php-hydrator). We want to get away from this
 * kinda pattern anyway, so maybe we can spoof this by just wrapping
 * this in a `section` element or whatever the analytics expects when
 * it's looking up the tree for a nearby Hydrator.
 *
 * Update: removed Hydrator because of some weird style inconsistencies.
 * Point above still stands until we are sure we are okay with removing
 * the Hydrator permanently.
 */
export function RecentlyViewed({ id, brandTitleMaxLine, slidesPer, hideComponentCallback }) {
  const { channel } = useContext(ExperienceContext);
  const { customerType } = useContext(HomepageContext);
  const { defaultVariables } = useContext(QueryContext);

  const slidesToShow = customerType !== 'b2b' ? 5 : 2;

  const newDefaultVariables = {
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant
      === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  return (
    <ErrorBoundary name="rv_homepage_rr">
      <QueryProvider defaultVariables={newDefaultVariables} cacheKey="rv_homepage_rr" persist>
        <section
          id="rv-php-hydrator"
          className="sui-h-full"
        >
          <Card
            className="sui-h-full sui-leading-none"
            // eslint-disable-next-line max-len
            CardContentProps={{ className: 'sui-flex sui-flex-col sui-w-full sui-h-full sui-gap-4 sui-p-4' }}
          >
            <CardTitle header={
              <Typography variant="h3">Recently Viewed</Typography>
            }
            />
            <RecentlyViewedComponent
              brandTitleMaxLine={brandTitleMaxLine}
              slidesToShow={{ sm: 2, md: 2, lg: slidesToShow, xl: slidesToShow }}
              hideTitle
              schemaName="rv_homepage_rr"
              showFallback
              entryId={id}
              hideATC
              hidePrice
              hideCarouselArrows={channel === 'mobile'}
            />
          </Card>
        </section>
      </QueryProvider>
    </ErrorBoundary>
  );
}
RecentlyViewed.propTypes = {
  brandTitleMaxLine: number,
  slidesPer: number,
  id: string,
  hideComponentCallback: func,
};

RecentlyViewed.defaultProps = {
  brandTitleMaxLine: undefined,
  slidesPer: undefined,
  id: undefined,
  hideComponentCallback: () => {},
};
