/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef, useState } from 'react';
import {
  arrayOf,
  bool,
  customType,
  id,
  number,
  params,
  shape,
  string,
  useDataModel
} from '@thd-nucleus/data-sources';
import { shape as shapeType, string as stringType, number as numberType, arrayOf as arrayOfType } from 'prop-types';

import { CardBody } from '@one-thd/sui-atomic-components';
import { ArrowForward } from '@one-thd/sui-icons';
import { OrderWrapper, LazyOrderWrapper } from '../subcomponents/orderWrapper/OrderWrapper';
import { RecentOrdersCardDate } from '../subcomponents/orderDate/RecentOrdersDate';
import { RecentOrdersCardReceipt } from '../subcomponents/orderReceipt/RecentOrdersReceipt';
import { RecentOrdersCardTotal } from '../subcomponents/orderTotal/RecentOrdersTotal';
import { CurbsideButton } from '../subcomponents/curbsideButton';
import { OrderStatus } from '../subcomponents/orderStatus';
import { CardOrderItemThumbnails } from '../subcomponents/orderItems';
import { getStoreNumber, isOnlineOrder } from '../../../recentPurchases/components/helpers';
import { purchaseHistoryURL, orderDetailsURL } from '../../../constants';

const LAZY_LOAD_INDEX_THRESHOLD = 2;

export function CardOrder({ customerInfo = {}, index = undefined, order = {}, windowWidth = undefined }) {
  const OrderWrapperComponent = index < LAZY_LOAD_INDEX_THRESHOLD ? OrderWrapper : LazyOrderWrapper;

  const [shouldFetchOrderDetails, setShouldFetchOrderDetails] = useState(index < LAZY_LOAD_INDEX_THRESHOLD);
  const orderCardRef = useRef();

  const orderNumber = order?.orderNumbers?.[0];
  const isOnline = isOnlineOrder(orderNumber);
  const searchType = isOnline ? 'online' : 'inStore';
  const storeNumber = getStoreNumber(order, isOnline);
  const userId = customerInfo?.userId;

  const requestVariables = {
    customerAccountId: customerInfo?.customerAccountId,
    groupAndSortBy: {
      groupBy: 'fulfillmentType',
    },
    orderNumber,
    searchType
  };

  const queryOptions = {
    variables: {
      userId,
      request: requestVariables,
      headers: {
        tmxProfileId: customerInfo.sessionId,
        channelId: customerInfo.channelId,
      }
    },
    skip: !userId || !shouldFetchOrderDetails,
    context: { withAuth: true },
  };

  const { data, loading, error } = useDataModel(
    'orderDetails',
    queryOptions,
  );

  const { orderDetails } = data || {};
  const orderStatus = orderDetails?.statusDescription;
  const formattedOrderStatus = orderStatus?.toLowerCase();

  const isReadyForPickup = formattedOrderStatus === 'ready for pickup';

  const isDelivered = formattedOrderStatus === 'delivered';
  const isCanceled = formattedOrderStatus === 'canceled';
  const isComplete = formattedOrderStatus === 'complete';
  const isShipping = !isDelivered && !isCanceled && !isComplete;

  const hasCurbsideLinkDetails = orderNumber && storeNumber;
  const curbsideIsAvailable = orderDetails?.fulfillmentGroups?.some((group) => group?.storePickup?.isCurbsidePickupAvailable);

  const showCurbsideButton = hasCurbsideLinkDetails
    && curbsideIsAvailable
    && isShipping;

  const showEstimatedDelivery = isShipping || isReadyForPickup;

  const orderLookupID = isOnline ? orderNumber : order.receiptDetails;

  const orderURL = orderLookupID ? `${orderDetailsURL}?orderNumber=${orderLookupID}` : purchaseHistoryURL;

  return (
    <OrderWrapperComponent
      index={index}
      loading={loading}
      orderNumber={orderNumber}
      ref={orderCardRef}
      setShouldFetchOrderDetails={setShouldFetchOrderDetails}
    >
      <CardBody>
        <a data-testid="specificOrderLink" className="sui-flex sui-justify-between" href={orderURL}>
          <div className="sui-w-5/6">
            <RecentOrdersCardReceipt
              orderReceiptDetails={orderLookupID}
            />
            <RecentOrdersCardDate orderDate={order.salesDate} />
            <RecentOrdersCardTotal orderTotal={orderDetails?.grandTotalAmount} orderOrigin={orderDetails?.orderOrigin} />
          </div>
          <ArrowForward />
        </a>
        <OrderStatus
          orderStatus={orderStatus}
          shippingDetails={orderDetails?.fulfillmentGroups[0]?.shippingAndHandling}
          showEstimatedDelivery={showEstimatedDelivery}
        />
      </CardBody>
      <div className="sui-h-32 sui-flex sui-flex-col sui-justify-end">
        <CardOrderItemThumbnails
          hasCurbsideButton={showCurbsideButton}
          href="/order/view/summary/"
          items={orderDetails?.lineItems}
          windowWidth={windowWidth}
        />
        {showCurbsideButton
          && (
            <CurbsideButton
              isReadyForPickup={isReadyForPickup}
              orderNumber={orderNumber}
              storeNumber={storeNumber}
            />
          )}
      </div>
    </OrderWrapperComponent>
  );
}

CardOrder.propTypes = {
  order: shapeType({
    orderNumbers: arrayOfType(stringType),
    receiptDetails: stringType,
    salesDate: stringType,
  }),
  customerInfo: shapeType({
    customerAccountId: stringType,
    userId: stringType,
  }),
  windowWidth: numberType,
  index: numberType,
};


CardOrder.dataModel = {
  orderDetails: params({
    userId: id().isRequired(),
    headers: customType('HeaderExtras').shape({
      tmxProfileId: string(),
      channelId: string()
    }),
    request: customType('OrderDetailsRequest!').shape({
      customerAccountId: string(),
      groupAndSortBy: shape({
        groupBy: string(),
      }),
      orderNumber: string(),
      searchType: string()
    }),
  }).shape({
    grandTotalAmount: string(),
    fulfillmentGroups: arrayOf(shape({
      fulfillmentType: string(),
      shippingAndHandling: shape({
        shipments: arrayOf(shape({
          shipment: shape({
            estimatedDeliveryDate: string()
          })
        }))
      }),
      storePickup: shape({
        isCurbsidePickupAvailable: bool(),
        pickUpStore: shape({
          number: string(),
          storeName: string(),
        })
      })
    })),
    lineItems: arrayOf(
      shape({
        itemDescFromCatalog: string(),
        quantity: number(),
        imageUrl: string(),
        skuNumber: number()
      })
    ),
    statusDescription: string(),
    orderOrigin: string(),
  }),
};