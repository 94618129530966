import React from 'react';
import { bool, string } from 'prop-types';
import { Card, Typography, Button } from '@one-thd/sui-atomic-components';
import { Orders } from '@one-thd/sui-icons';
import { useImpression } from '@thd-olt-component-react/impression';
import { RecentOrdersCardHeader } from '../subcomponents/cardHeader/RecentOrdersHeader';
import { purchaseHistoryURL } from '../../../constants';
function RecentOrdersFallbackCard({ isError = false, noData = false, componentId = '' }) {
  let headerMessage;
  let subheaderMessage;
  let testIdType;

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'RecentOrdersFallbackCard',
      component: 'RecentOrdersFallbackCard',
      position: 1,
      type: 'content',
    },
  });

  if (isError) {
    testIdType = 'error';
    headerMessage = (
      <Typography variant="h3">We couldn&apos;t load your order</Typography>
    );
    subheaderMessage = (
      <Typography>Try refreshing the page or check back later.</Typography>
    );
  } else if (noData) {
    testIdType = 'fallback';
    headerMessage = <Typography variant="h3">No Recent Orders</Typography>;
    subheaderMessage = (
      <Typography>Visit your Order History to see older orders</Typography>
    );
  }

  return (
    <Card
      data-testid={`recentOrders-card-${testIdType}`}
      className="sui-h-full sui-w-full"
      data-component="RecentOrdersFallbackCard"
      ref={ref}
      clickid={clickID}
    >
      <RecentOrdersCardHeader />
      <div
        data-testid={`recentOrders-content-${testIdType}`}
        className="sui-flex sui-items-center sui-h-96 sui-w-full sui-flex-col sui-justify-center"
        data-type="container"
      >
        <Orders color="subtle" size="large" />
        <p className="sui-mt-8 sui-mb-4">{headerMessage}</p>
        <p className="sui-mb-8">{subheaderMessage}</p>
      </div>
      <Button fullWidth href={purchaseHistoryURL} target="_blank" variant="secondary">View Order History</Button>
    </Card>
  );
}

RecentOrdersFallbackCard.propTypes = {
  isError: bool,
  noData: bool,
  componentId: string,
};

export { RecentOrdersFallbackCard };
