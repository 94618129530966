import React, { useState, useCallback } from 'react';

import {
  arrayOf,
  shape,
  string,
  number,
  func,
  bool
} from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Typography,
  TextField,
} from '@one-thd/sui-atomic-components';
import { Add, Edit } from '@one-thd/sui-icons';
import { getValidationError } from '../../helpers';
import { publishAnalytics } from '../../../../utils';

export function RecentPurchasesTableJobName({
  POJobName = null,
  order = {},
  customerInfo = {},
  isOnline =  false,
  onError = () => {},
  mutatePOJobName = () => {},
  storeNumber,
}) {
  const [overlay, setOverlay] = useState(false);

  const [poJobName, setPoJobName] = useState(POJobName);
  // To be new POJobName
  const [inputFieldValue, setInputFieldValue] = useState(POJobName || '');
  const [error, setError] = useState(null);

  const isDisabled = Boolean(error);
  const validatePoJobName = useCallback(function validatePoJobName(event) {
    const eventValue = event.target.value;
    const validationError = getValidationError({ value: eventValue });

    if (!validationError) {
      setError(null);
    } else {
      setError(validationError);
    }

    // The Input component we use expects false in the case a validation error exists, hence the !validationError
    return eventValue && !validationError;
  }, []);

  const handleChange = useCallback(function handleChange(event) {
    setInputFieldValue(event.target.value);
    validatePoJobName(event);
  }, []);

  const { salesDate, orderNumbers } = order;

  const requestVariables = {
    customerAccountId: customerInfo.customerAccountId,
    salesDate,
    newPOJobName: inputFieldValue,
    storeNumber: `${storeNumber}`,
    registerNumber: isOnline ? 0 : order.registerNumber,
    transactionId: isOnline ? 0 : order.transactionId,
  };

  if (isOnline) {
    const [orderNumber] = orderNumbers || [];
    requestVariables.orderNumber = orderNumber;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const { errors: mutationError } = await mutatePOJobName({
      variables: { userId: customerInfo.userId, request: requestVariables },
      context: { withAuth: true },
    });

    setPoJobName((originalPoJobName) => {
      if (mutationError) {
        return originalPoJobName;
      }
      // New POJobName
      setOverlay(false);
      return inputFieldValue;
    });
    setInputFieldValue(inputFieldValue);
    publishAnalytics('recentPurchases.save-po-click');

    setError(() => {
      if (mutationError) {
        const isUnauthorized = mutationError?.some(
          (gqlError) => gqlError?.extensions['X-Http-Status-Code'] === 401);

        if (isUnauthorized) {
          onError('authorizationError', 'Error in session validation');
          return 'AuthorizationError';
        }

        setOverlay(true);
        return `Something went wrong.
          We had a technical issue and couldn't process your request. Try
          again or check back later.`;
      }
      return null;
    });
  }

  function handlePoJobNameClick() {
    setOverlay(true);
    publishAnalytics('recentPurchases.edit-po-click');
  }

  return (
    <div className="sui-flex sui-w-full sui-items-center sui-h-20 sui-overflow-hidden sui-max-w-fit">
      <button
        type="button"
        className="sui-w-full sui-h-full hover:sui-text-brand hover:sui-fill-text-brand"
        onClick={() => handlePoJobNameClick()}
      >
        <div className="sui-flex">
          <div className="sui-h-4 sui-w-4 sui-mr-2">
            {poJobName ? <Edit size="small" /> : <Add size="small" />}
          </div>
          <Typography align="left" lineClamp="2">
            <span className="hover:sui-text-brand sui-break-all">{poJobName || 'Add'}</span>
          </Typography>
        </div>
      </button>
      {overlay ? (
        <Modal open={overlay} onClose={() => setOverlay(false)}>
          <ModalHeader onClose={() => setOverlay(false)}>
            Edit PO/Job Name
          </ModalHeader>
          <form data-testid="poJobNameForm" onSubmit={handleSubmit}>
            <ModalBody>
              <TextField
                type="text"
                autoFocus
                fullWidth
                value={inputFieldValue}
                statusMessage={error}
                label="PO/JobName"
                status={error ? 'error' : null}
                onChange={handleChange}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                fullWidth
                type="submit"
                disabled={!inputFieldValue || isDisabled}
              >
                Submit
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      ) : null}
    </div>
  );
}

export function RecentPurchasesCardJobName({ POJobName = null }) {
  const poJobName = POJobName || 'No PO/Job Name';

  return (
    <div className="sui-flex sui-items-center">
      <span className="sui-mr-2">
        <Typography weight="bold">PO/Job Name</Typography>
      </span>
      <span className="sui-basis-1/2 sui-overflow-hidden">
        <Typography truncate>{poJobName}</Typography>
      </span>
    </div>
  );
}

RecentPurchasesTableJobName.propTypes = {
  POJobName: string,
  order: shape({
    salesDate: string,
    orderNumber: string,
    orderNumbers: arrayOf(string),
    totalAmount: number,
    receiptDetails: string,
    registerNumber: number,
    orderOrigin: string,
    detailsSource: string,
    transactionId: number,
    POJobName: string,
    transactionType: string,
  }),
  customerInfo: shape({
    userId: string,
    customerAccountId: string,
  }),
  onError: func,
  isOnline: bool,
  mutatePOJobName: func,
  storeNumber: number.isRequired,
};

RecentPurchasesCardJobName.propTypes = {
  POJobName: string,
};

