/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, {
  useEffect, useContext
} from 'react';
import {
  string,
  number,
  arrayOf,
  shape,
  element,
  any,
  bool,
  func,
  oneOf,
} from 'prop-types';
import { useVariationIds } from '@thd-olt-component-react/personalization-utils';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { getThemeByName } from '@thd-olt-component-react/stencil-themes';
import { ThemeProvider } from '@one-thd/sui-atomic-components';
import { Layout } from './Layout';
import useLayoutOrchestration from '../hooks/useLayoutOrchestration';
import { getFirstHeroImageUrl } from '../Orchestration/dataUtils';

const LayoutRenderer = ({
  layoutOperations = [],
  componentOptions = {},
  components = {},
  layoutDataModel = {},
  contentfulPreviewId = null,
  layoutClass = null,
  slug = null,
  loadingLayout: LoadingLayout = null,
  onContentInformationApiError = () => null,
  ssr = false,
  renderMetadata = () => null,
  customerType = null,
}) => {
  const variables = contentfulPreviewId ? { id: contentfulPreviewId, layoutClass } : { slug };

  const layoutOptions = {
    componentOptions,
    myComponents: components,
  };

  if (layoutOperations?.length > 0) {
    layoutOptions.layoutOperations = layoutOperations;
  }

  const {
    data: layoutData,
    loading,
    error,
  } = useLayoutOrchestration({
    variables,
    layoutDataModel,
    layoutOptions,
    ssr,
    customerType,
  });

  const { channel } = useContext(ExperienceContext);

  const mboxes = layoutData?.mboxes || [];
  const campaignIds = layoutData?.campaignIds || [];
  const innervateTags = layoutData?.innervateTags || [];
  const isReady = layoutData?.isReady;

  const { variationIds, renderDefault } = useVariationIds({
    mboxes,
    campaignIds,
    innervateTags,
    isReady,
    channel,
  });

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('layout-renderer.ready');
  }, []);

  if (error.server || error.client) {
    return onContentInformationApiError(error);
  }

  const preloadImageUrl = getFirstHeroImageUrl({ layoutComponents: layoutData?.components?.all, channel });
  const preloadImageId = channel === 'desktop' ? 'preloadHeroLargeImage' : 'preloadHeroSmallImage';

  const theme = layoutData?.pageTheme;

  return (
    <>
      {layoutData?.seo && ssr
        ? renderMetadata({
          preloadImageUrl,
          preloadImageId,
          metadata: layoutData.seo
        })
        : <></>}
      {layoutData?.components?.themed?.length > 0 && (
        <>
          <ThemeProvider ssr theme={getThemeByName(theme)}>
            <div id="themed-container" className="sui-bg-primary sui-text-primary">
              <Layout
                layoutType="themed-layout"
                components={components}
                layoutComponents={layoutData.components.themed}
                componentSelectorData={{
                  variationIds,
                  renderDefault,
                  mboxes: layoutData?.mboxes,
                  campaignIds: layoutData?.campaignIds,
                  innervateTags: layoutData?.innervateTags
                }}
                loading={loading}
                LoadingLayout={LoadingLayout}
              />
            </div>
          </ThemeProvider>
          {theme === 'PrimaryDarkTheme' && (
            <ThemeProvider ssr theme={getThemeByName('PrimaryGreyTheme')}>
              <div className="sui-h-4 sui-bg-primary sui-text-primary sui-mb-10">
                {/* Grey transition area */}
              </div>
            </ThemeProvider>
          )}
        </>
      )}
      {layoutData?.components?.nonThemed && (
        <Layout
          layoutType="default-layout"
          components={components}
          layoutComponents={layoutData.components.nonThemed}
          componentSelectorData={{
            variationIds,
            renderDefault,
            mboxes: layoutData?.mboxes,
            campaignIds: layoutData?.campaignIds,
            innervateTags: layoutData?.innervateTags
          }}
          loading={loading}
          LoadingLayout={LoadingLayout}
          alwaysHydrate={layoutData?.components?.themed?.length > 0}
        />
      )}
    </>
  );
};

LayoutRenderer.propTypes = {
  components: shape({
    [string]: element,
  }).isRequired,
  layoutOperations: arrayOf(string),
  componentOptions: shape({
    [string]: shape({
      locked: shape({
        xPos: number,
        yPos: number,
      }),
      sizes: shape({
        minWidthMedium: {
          min: number,
          preferred: number,
          max: number,
        },
        minWidthLarge: {
          min: number,
          preferred: number,
          max: number,
        },
      }),
    }),
  }),
  slug: string,
  // eslint-disable-next-line react/forbid-prop-types
  layoutDataModel: any,
  loadingLayout: element,
  contentfulPreviewId: string,
  // eslint-disable-next-line react/forbid-prop-types
  layoutClass: oneOf(['PersonalizedHomePage', 'EducationalLayout']),
  onContentInformationApiError: func,
  ssr: bool,
  renderMetadata: func,
  customerType: string,
};

LayoutRenderer.displayName = 'LayoutRenderer';

export { LayoutRenderer };
