/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  CardMedia,
  CardTitle,
  CardBody,
  Typography,
  Link,
  Button,
} from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import { clickEvent, impressionEvent } from '../../utils/publisher';
import { CARD_IMAGE } from '../../utils/constants';
import { redirectToSavings, redirectToAppliances } from '../../utils/general';
import {
  getCurrentPromotionDates,
  loadEstimatorScript
} from '../../utils/payment-estimator-utils';
import TermsAndConditionsDrawer from '../../common/terms-and-conditions-drawer.component';

const ApplyNow = ({
  paymentEstimatorVersion, isMobile, id, isECM
}) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [paymentEstimatorDetails, setPaymentEstimatorDetails] = useState(null);
  const {
    activeEndDate, activeStartDate, tooltipText, applyNowUrl
  } = paymentEstimatorDetails || {};

  const openDrawer = () => {
    setShowDrawer(true);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const analyticsInteraction = () => {
    clickEvent('plcc-apply now');
  };

  useEffect(() => {
    const onPaymentEstimatorLoad = () => {
      setPaymentEstimatorDetails(getCurrentPromotionDates(0, isMobile, true, true, 'homepage'));
      impressionEvent('plcc-apply now');
    };

    loadEstimatorScript(
      paymentEstimatorVersion,
      onPaymentEstimatorLoad,
      isMobile
    );
  }, []);

  const { ref, clickID } = useImpression({
    data: {
      id,
      name: 'PLCCPod',
      component: 'PLCCPod',
      position: 1,
      type: 'content',
    },
  });

  const isAppliancePromo = tooltipText?.includes('Appliance purchases.');
  const isTieredPromo = tooltipText?.includes('18 Months on purchases');
  const isTieredAppliancePromo = tooltipText?.includes('18 Months on Appliance');
  const isSpecialPromo = tooltipText?.includes('$299+.');

  const promoDateRange = activeStartDate && activeEndDate ? `Valid ${activeStartDate} - ${activeEndDate}` : '';
  let promoTitle = <span><b>6 Months Everyday Financing**</b> available on purchases of $299 or more.</span>;
  let promoMessage = (
    <span>
      <b>6M</b> NO INTEREST IF PAID IN FULL WITHIN 6 MONTHS** on
      purchases of $299 or more made on The Home Depot Consumer Credit Card.
    </span>
  );
  let cardDetails = CARD_IMAGE.M06_HOMEDEPOT;

  if (isSpecialPromo) {
    promoTitle = <span><b>12 Months Financing**</b> available on purchases of $299 or more.</span>;
    promoMessage = (
      <span>
        <b>12M </b>
        NO INTEREST IF PAID IN FULL WITHIN 12 MONTHS** on purchases
        of $299 or more made on The Home Depot Consumer Credit Card.
        {promoDateRange}.
      </span>
    );
    cardDetails = CARD_IMAGE.M12_HOMEDEPOT;
  }

  if (isTieredPromo) {
    promoTitle = <span><b>Up to 24 Months Financing**</b> available on purchases of $299 or more.</span>;
    promoMessage = (
      <span>
        <b>Up to 24M</b> NO INTEREST IF PAID IN FULL WITHIN PROMOTIONAL PERIOD: 12 Months**
        on Purchases of $299–$998, 18 Months** on Purchases of $999–$1,998 or 24 Months** on
        Purchases of $1,999 or More. {promoDateRange}.
      </span>
    );
    cardDetails = CARD_IMAGE.M24_HOMEDEPOT;
  }

  if (isAppliancePromo) {
    promoTitle = <span><b>12 Months Financing**</b> available on Appliance purchases of $299 or more.</span>;
    promoMessage = (
      <span>
        <b>12M</b> NO INTEREST IF PAID IN FULL WITHIN 12 MONTHS** on
        Appliance purchases of $299 or more made on The Home Depot Consumer Credit Card. {promoDateRange}.
      </span>
    );
    cardDetails = CARD_IMAGE.A12_HOMEDEPOT;
  }

  if (isTieredAppliancePromo) {
    promoTitle = <span><b>Up to 24 Months Financing**</b> available on Appliance purchases of $299 or more.</span>;
    promoMessage = (
      <span>
        <b>Up to 24M</b> NO INTEREST IF PAID IN FULL WITHIN PROMOTIONAL PERIOD:
        12 Months** on Appliance purchases of $299–$998, 18 Months** on Appliance purchases
        of $999–$1,998 or 24 Months**  on Appliance purchases of $1,999 or More. {promoDateRange}.
      </span>
    );
    cardDetails = CARD_IMAGE.A24_HOMEDEPOT;
  }

  const renderButton = () => {
    if (isECM) {
      if (isSpecialPromo || isTieredPromo) {
        return (
          <Button data-testid="savings-button" variant="secondary" fullWidth onClick={() => redirectToSavings()}>
            Shop Specials & Offers
          </Button>
        );
      } if (isAppliancePromo || isTieredAppliancePromo) {
        return (
          <Button data-testid="appliances-button" variant="secondary" fullWidth onClick={() => redirectToAppliances()}>
            Shop Appliance Sales
          </Button>
        );
      }
    }
    return (
      <Button
        data-testid="apply-now-regular"
        variant="secondary"
        fullWidth
        href={applyNowUrl}
        onClick={analyticsInteraction}
      >
        Apply Now
      </Button>
    );
  };

  const applyNowMessage = () => {
    return (
      // eslint-disable-next-line react/no-unknown-property
      <div clickid={clickID} ref={ref}>
        <div className="sui-flex sui-place-content-center sui-bg-subtle">
          <CardMedia
            className="sui-object-fill"
            src={cardDetails}
            alt="Credit Card Icon"
            aspect="wide"
          />
        </div>
        <CardBody>
          <div className="sui-flex sui-flex-col sui-gap-4 sui-pt-4 sui-pb-4">
            <Typography>
              <h6 fontWeight="normal">
                {/* TODO: change the promoTitles to use Typography direcly, avoiding rendering errors */}
                {promoTitle}
              </h6>
            </Typography>
            <Typography variant="body-base">
              {promoMessage}
            </Typography>
            <Typography variant="body-xs" color="subtle">
              See{' '}
              <span className="sui-align-text-bottom">
                <Link
                  component="button"
                  onClick={openDrawer}
                  variant="body-xs"
                >
                  terms & conditions
                </Link>
              </span>{' '}
              for offer details.
            </Typography>
          </div>
        </CardBody>
        <div>
          {renderButton()}
        </div>
      </div>
    );
  };

  return (
    <CardContent
      className="sui-flex sui-flex-col sui-gap-4 sui-h-full sui-w-full"
      clickid={clickID}
      ref={ref}
      data-component="ApplyNow"
    >
      <CardTitle
        disableTypography
        header={(
          <Typography variant="h3">Home Depot Credit Cards</Typography>
        )}
      />
      {showDrawer && (
        <TermsAndConditionsDrawer
          closeDrawer={closeDrawer}
          open={showDrawer}
          applyNowClick={() => clickEvent('plcc-apply now')}
          showFinancing
          isAppliance={isAppliancePromo || isTieredAppliancePromo}
          isEcmPromo={isECM}
        />
      )}
      {applyNowMessage()}
    </CardContent>
  );
};

ApplyNow.propTypes = {
  id: PropTypes.string,
  paymentEstimatorVersion: PropTypes.string,
  isMobile: PropTypes.bool,
  isECM: PropTypes.bool,
};
ApplyNow.defaultProps = {
  id: '',
  paymentEstimatorVersion: 'v1.0.29',
  isMobile: false,
  isECM: false,
};

export default ApplyNow;
