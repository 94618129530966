import React from 'react';
import { string as stringType, number, shape as shapeType } from 'prop-types';
import {
  params,
  arrayOf,
  string,
  fragment,
  shape,
  useDataModel,
  customType
} from '@thd-nucleus/data-sources';
import { CapabilitiesTileRenderer } from './CapabilitiesTileRenderer';

export function CapabilitiesTile({
  componentId,
  componentClass = '',
  componentPosition = 0,
  id = undefined,
}) {
  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  return (
    <CapabilitiesTileRenderer
      componentId={componentId}
      id={id}
      componentClass={componentClass}
      componentPosition={componentPosition}
      dataComponent={data?.component}
    />
  );
}

CapabilitiesTile.propTypes = {
  componentId: stringType.isRequired,
  componentClass: stringType,
  id: stringType,
  componentPosition: number,
};

CapabilitiesTile.displayName = 'Capabilities Tile';

CapabilitiesTile.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['CapabilitiesTile']).isRequired(),
  }).shape({
    CapabilitiesTile: fragment().shape({
      link: string(),
      id: string(),
      componentName: string(),
      title: string(),
      description: string(),
      cta: string(),
      previewImage: shape({
        damContentSelector: shape({
          assetData: arrayOf(shape({
            selectedImageUrl: string()
          }))
        }),
        damDownloadedContent: shape({
          url: string()
        })
      }),
    }),
  }),
};
